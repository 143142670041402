var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content full checklist-list"},[(_vm.filter_visible && !_vm.condensedView)?_c('div',{staticClass:"filter-column hide-scrollbar"},[_c('label',[_vm._v(_vm._s(_vm.$t('vtf.search')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"input-search",attrs:{"type":"text"},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}}),_vm._l((_vm.main_filters),function(itm,index){return _c('div',{key:index,staticClass:"filter",attrs:{"data-type":itm.type}},[_c('div',{staticClass:"value"},[(itm.type == 'multiple')?_c('v-select',{attrs:{"items":_vm.getOptions( itm.options, itm ),"multiple":"","chips":"","label":itm.label,"placeholder":_vm.$t('nvgt.select.all')},on:{"change":function($event){return _vm.updateFilters()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.text))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"caption"},[_vm._v("(+"+_vm._s(itm.current_value.length - 1)+" "+_vm._s(_vm.$tc('nvgt.select.selected', itm.current_value.length - 1))+")")]):_vm._e()]}}],null,true),model:{value:(itm.current_value),callback:function ($$v) {_vm.$set(itm, "current_value", $$v)},expression:"itm.current_value"}}):_vm._e(),(itm.type == 'select')?_c('v-select',{attrs:{"items":_vm.getOptions( itm.options, itm ),"label":itm.label},on:{"change":function($event){return _vm.updateFilters()}},model:{value:(itm.current_value),callback:function ($$v) {_vm.$set(itm, "current_value", $$v)},expression:"itm.current_value"}}):_vm._e(),(itm.type == 'selectDate')?_c('v-select',{attrs:{"items":_vm.getOptions( itm.options, itm )},on:{"change":function($event){return _vm.updateFilters()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-list-tile-content',[_c('v-list-tile-title',{domProps:{"innerHTML":_vm._s(item.text)}}),_c('v-list-tile-sub-title',[_vm._v(_vm._s(_vm.momentInitEnd(itm.current_value[0], itm.current_value[1])))])],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(!item.action)?_c('v-list-tile-content',[_c('v-list-tile-title',[_vm._v(_vm._s(item.text))])],1):_vm._e(),(item.action)?_c('span',{staticClass:"btn-select",on:{"click":function($event){return _vm.filterDateRange(item.value)}}},[_vm._v(_vm._s(item.text))]):_vm._e()]}}],null,true),model:{value:(itm.current_value),callback:function ($$v) {_vm.$set(itm, "current_value", $$v)},expression:"itm.current_value"}}):_vm._e()],1)])})],2):_vm._e(),_c('div',{staticClass:"data-column",class:{ 'large' : !_vm.filter_visible || _vm.condensedView, 'condensed':_vm.condensedView }},[(!_vm.condensedView)?_c('div',{staticClass:"filter-bar"},[_c('div',{staticClass:"bttns"},[_c('button',{staticClass:"btn open-filter",class:{'opened':_vm.filter_visible},on:{"click":function($event){_vm.filter_visible = !_vm.filter_visible}}})]),_c('div',{staticClass:"filters"},_vm._l((_vm.main_filters),function(itm,index){return _c('div',{key:index,staticClass:"filter",attrs:{"data-type":itm.type}},[_c('div',{staticClass:"value"},[(itm.type == 'multiple')?_c('v-select',{attrs:{"items":_vm.getOptions( itm.options, itm ),"multiple":"","chips":"","label":itm.label,"placeholder":_vm.$t('nvgt.select.all')},on:{"change":function($event){return _vm.updateFilters()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.text))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"caption"},[_vm._v("(+"+_vm._s(itm.current_value.length - 1)+" "+_vm._s(_vm.$tc('nvgt.select.selected', itm.current_value.length - 1))+")")]):_vm._e()]}}],null,true),model:{value:(itm.current_value),callback:function ($$v) {_vm.$set(itm, "current_value", $$v)},expression:"itm.current_value"}}):_vm._e(),(itm.type == 'select')?_c('v-select',{attrs:{"items":_vm.getOptions( itm.options, itm ),"label":itm.label},on:{"change":function($event){return _vm.updateFilters()}},model:{value:(itm.current_value),callback:function ($$v) {_vm.$set(itm, "current_value", $$v)},expression:"itm.current_value"}}):_vm._e(),(itm.type == 'selectDate')?_c('v-select',{attrs:{"items":_vm.getOptions( itm.options, itm )},on:{"change":function($event){return _vm.updateFilters()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-list-tile-content',[_c('v-list-tile-title',{domProps:{"innerHTML":_vm._s(item.text)}}),_c('v-list-tile-sub-title',[_vm._v(_vm._s(_vm.momentInitEnd(itm.current_value[0], itm.current_value[1])))])],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(!item.action)?_c('v-list-tile-content',[_c('v-list-tile-title',[_vm._v(_vm._s(item.text))])],1):_vm._e(),(item.action)?_c('span',{staticClass:"btn-select",on:{"click":function($event){return _vm.filterDateRange(item.value)}}},[_vm._v(_vm._s(item.text))]):_vm._e()]}}],null,true),model:{value:(itm.current_value),callback:function ($$v) {_vm.$set(itm, "current_value", $$v)},expression:"itm.current_value"}}):_vm._e()],1)])}),0),_c('div',{staticClass:"editremove"},[_c('button',{staticClass:"remove",on:{"click":function($event){return _vm.remove()}}},[_vm._v(_vm._s(_vm.getString('nvgt.remove')))])])]):_vm._e(),_c('div',{staticClass:"list"},[_c('div',{staticClass:"marginAdd"},[(_vm.showAdd)?_c('div',{staticClass:"add",on:{"click":function($event){_vm.showAdd=false}}},[_vm._v(_vm._s(_vm.getString('nvgt.'+ _vm.currentRoute +'.add')))]):_vm._e()]),(!_vm.showAdd && _vm.currentRoute == 'ManageLabelCategory')?_c('CategoryItem',{attrs:{"gValue":_vm.newItem,"gNew":true},on:{"callCancel":_vm.changeAdd}},[_vm._v("Añadir categoría")]):_vm._e(),_vm._l((_vm.values),function(item){return (_vm.currentRoute == 'ManageLabelCategory')?_c('CategoryItem',{key:item.id,attrs:{"gValue":item},on:{"callUp":_vm.upOrder,"callDown":_vm.downOrder,"callSelected":_vm.addremoveSelected}}):_vm._e()}),(!_vm.showAdd && _vm.currentRoute == 'ManageLabelState')?_c('StateItem',{attrs:{"gValue":_vm.newItem,"gNew":true},on:{"callCancel":_vm.changeAdd}},[_vm._v("Añadir categoría")]):_vm._e(),_vm._l((_vm.values),function(item){return (_vm.currentRoute == 'ManageLabelState')?_c('StateItem',{key:item.id,attrs:{"gValue":item},on:{"callUp":_vm.upOrder,"callDown":_vm.downOrder,"callSelected":_vm.addremoveSelected}}):_vm._e()})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }