<template>
    <div class="group">
        <div class="contain">
            <div name="select" class="check" v-on:click="addeleteSelected()" v-if="!isNew">
                <div class="tick" v-if="selected"></div>
            </div>
            <div class="name" v-if="edited || isNew">
                <input type="text" v-model="value.name" autofocus />
                <span class="status" v-on:click="addeleteChecked()" :class="{ checked: value.status }">
                    <input class="switch-input" type="checkbox" :checked="value.status" />
                    {{ value.status ? getString('nvgt.status.status1') : getString('nvgt.status.status0') }}
                </span>
            </div>
            <div class="name" v-else>
                <span class="default">{{ value.name }}</span>
                <span class="status statusimg" :class="{ checked: value.status }">{{ value.status ? getString('nvgt.status.status1') : getString('nvgt.status.status0') }}</span>
            </div>
            <div class="arrows" :class="{ editModeArrow: edited || isNew }">
                <button class="top" v-on:click="upOrder()"></button>
                <button class="bottom" v-on:click="downOrder()"></button>
            </div>
            <div v-if="isNew" class="actions editMode">
                <div class="cancel" v-on:click="cancelNew()">{{ getString('nvgt.cancel') }}</div>
                <div class="save" v-on:click="saveNew()">{{ getString('nvgt.save') }}</div>
            </div>
            <div v-else-if="edited" class="actions editMode">
                <div class="cancel" v-on:click="cancelEdited(0)">{{ getString('nvgt.cancel') }}</div>
                <div class="save" v-on:click="cancelEdited(1)">{{ getString('nvgt.save') }}</div>
            </div>
            <div class="actions" v-on:click="addEdited()" v-else>
                <div class="edit" v-on:click="savetmp()">{{ getString('nvgt.edit') }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'

export default {
    // name: "categoryItem",
    props: {
        gValue: Object,
        gNew: { type: Boolean, default: false }
    },
    data() {
        return {
            selected: false,
            edited: false,
            tmpCheck: null,
            tmpName: null,
            filter_visible: true,
            filters: this.$store.getters['navigation/getCurrentPage'].filters ? JSON.parse(JSON.stringify(this.$store.getters['navigation/getCurrentPage'].filters)) : false,
            selects: this.$store.getters['navigation/getCurrentPage'].selects ? JSON.parse(JSON.stringify(this.$store.getters['navigation/getCurrentPage'].selects)) : false,
            actions: this.$store.getters['navigation/getCurrentPage'].actions ? JSON.parse(JSON.stringify(this.$store.getters['navigation/getCurrentPage'].actions)) : false,
            navInfo: this.$store.getters['navigation/getCurrentPage'] ? JSON.parse(JSON.stringify(this.$store.getters['navigation/getCurrentPage'])) : false
        }
    },
    computed: {
        value: {
            get() {
                return this.gValue
            },
            set(val) {
                // console.log("seted value");
            }
        },
        isNew() {
            return this.gNew
        },
        data() {
            return this.gData
        }
    },
    methods: {
        getString(str) {
            return this.$t(str)
        },
        isValid(value) {
            var result = false

            if (typeof value == 'object') {
                if (typeof value != 'undefined' && value.length > 0) {
                    result = true
                }
            } else {
                if (typeof value != 'undefined' && String(value) != '') {
                    result = true
                }
            }

            return result
        },
        savetmp() {
            this.tmpCheck = this.value.status
            this.tmpName = this.value.name
        },
        addeleteSelected() {
            this.selected = !this.selected
            if (this.selected) {
                this.$emit('callSelected', this.value, 1)
            } else {
                this.$emit('callSelected', this.value, 0)
            }
        },
        addeleteChecked() {
            if (this.value.status == 0) {
                this.value.status = 1
            } else if (this.value.status == 1) {
                this.value.status = 0
            }
        },
        addEdited() {
            this.edited = true
        },
        cancelEdited(num) {
            this.edited = false
            if (num == 0) {
                this.value.status = this.tmpCheck
                this.value.name = this.tmpName
            }
            if (num == 1) {
                let self = this
                let store = self.navInfo.storeName
                let type = self.navInfo.storeType

                self.$store.dispatch(store + '/saveResult', { item: this.value, type: type }).then(function() {
                    let call = self.navInfo.loadList ? store + '/' + self.navInfo.loadList : store + '/loadList'

                    self.$store.dispatch(call, { type: type }).then(function(response) {})
                })
            }
        },
        upOrder() {
            this.$emit('callUp', this.value)
        },
        downOrder() {
            this.$emit('callDown', this.value)
        },
        cancelNew() {
            this.$emit('callCancel', true)
        },
        saveNew() {
            let self = this
            let store = self.navInfo.storeName
            let type = self.navInfo.storeType
            // console.log(this.value)
            self.$store.dispatch(store + '/saveNew', { item: this.value, type: type }).then(function() {
                let call = self.navInfo.loadList ? store + '/' + self.navInfo.loadList : store + '/loadList'

                self.$store.dispatch(call, { type: type }).then(function(response) {})
            })
            this.$emit('callCancel', true)
            this.value.name = ''
            this.value.status = 1
        }
    },

    watch: {}
}
</script>

<style lang="scss">
#content.checklist-view .checklist-list .group {
    text-align: center;
    display: block;
    width: 50%;
    margin: 0 auto;
    .contain {
        margin: 10px 0px;
        display: grid;
        grid-template-columns: 40px auto 40px;
        grid-template-areas:
            'check name arrows'
            '.  edit .';
        .check {
            grid-area: check;
            place-self: center;
            border: 1px solid #777;
            border-radius: 2px;
            height: 15px;
            width: 15px;
            .tick {
                position: inherit;
                width: 20px;
                padding-bottom: 10px;
                @include background($image: img('tick_primary.svg'), $size: 15px, $position: left 6px center);
            }
        }
        .name {
            grid-area: name;
            align-self: center;
            height: 100%;
            background-color: #eee;
            display: grid;
            border-radius: 2px;
            grid-template-columns: 70% 30%;
            grid-template-areas: 'value status';
            .value {
                grid-area: value;
                align-self: center;
                padding: 5px;
            }
            .status {
                grid-area: status;
                padding: 5px;
                align-self: center;
                justify-self: end;
                padding-left: 25px;
                .switch-input {
                    position: relative;
                    top: 2px;
                    width: 25px;
                    height: 15px;
                    &:after {
                        width: 12px;
                        height: 12px;
                    }
                }
            }
            .statusimg {
                @include background($image: img('error_alert.svg'), $size: 15px, $position: left 6px center);
            }
            .checked {
                background-image: img('checked_primary.svg');
            }
            .default {
                padding: 5px;
                align-self: center;
                justify-self: initial;
                margin-left: 10px;
            }
        }
        .actions {
            grid-area: edit;
            text-align: right;
            padding-top: 5px;

            visibility: hidden;
            justify-self: end;
            .edit {
                text-align: right;
                @include background($image: img('edit_primary.svg'), $size: 15px, $position: right 6px center);
                padding-right: 25px;
            }
            .save,
            .cancel {
                @include background($size: 15px, $position: left 6px center);
                display: inline-block;
                margin-left: 5px;
                padding-left: 25px;
            }
            .cancel {
                background-image: img('error_alert.svg');
            }
            .save {
                background-image: img('checked_ok.svg');
            }
        }
        .arrows {
            grid-area: arrows;
            display: grid;
            grid-template-columns: 100%;
            padding: 10px;
            margin: 0 auto;
            visibility: hidden;
            .top,
            .bottom {
                width: 15px;
                height: 15px;
                text-align: center;
                @include background($size: 15px, $position: center 6px center);
            }
            .top {
                background-image: img('up_dark.svg');
            }
            .bottom {
                background-image: img('down_dark.svg');
            }
        }
        .editMode {
            visibility: visible;
        }
        .editModeArrow {
            visibility: hidden;
        }
        .editModeArrow:hover {
            visibility: hidden;
        }
    }
}
</style>
