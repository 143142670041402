<template>
  <div class="content full checklist-list">
    <div v-if="filter_visible && !condensedView" class="filter-column hide-scrollbar">
      <label>{{ $t('vtf.search') }}</label>
      <input class="input-search" type="text" v-model="search" />

      <div v-for="(itm, index) in main_filters" :key="index" class="filter" :data-type="itm.type">
        <!-- <div class="label">{{ itm.label }}</div> -->
        <div class="value">
          <v-select
            v-if="itm.type == 'multiple'"
            v-model="itm.current_value"
            :items="getOptions( itm.options, itm )"
            @change="updateFilters()"
            multiple
            chips
            :label="itm.label"
            :placeholder="$t('nvgt.select.all')"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0">
                <span>{{ item.text }}</span>
              </v-chip>
              <span
                v-if="index === 1"
                class="caption"
              >(+{{ itm.current_value.length - 1 }} {{$tc('nvgt.select.selected', itm.current_value.length - 1)}})</span>
            </template>
          </v-select>

          <v-select
            v-if="itm.type == 'select'"
            v-model="itm.current_value"
            :items="getOptions( itm.options, itm )"
            @change="updateFilters()"
            :label="itm.label"
          ></v-select>

          <v-select
            v-if="itm.type == 'selectDate'"
            v-model="itm.current_value"
            :items="getOptions( itm.options, itm )"
            @change="updateFilters()"
          >
            <template v-slot:selection="{ item, index }">
              <v-list-tile-content>
                <v-list-tile-title v-html="item.text"></v-list-tile-title>
                <v-list-tile-sub-title>{{ momentInitEnd(itm.current_value[0], itm.current_value[1]) }}</v-list-tile-sub-title>
              </v-list-tile-content>
            </template>
            <template v-slot:item="{ item, index }">
              <v-list-tile-content v-if="!item.action">
                <v-list-tile-title>{{item.text}}</v-list-tile-title>
              </v-list-tile-content>
              <span
                v-if="item.action"
                class="btn-select"
                @click="filterDateRange(item.value)"
              >{{item.text}}</span>
            </template>
          </v-select>
        </div>
      </div>
    </div>

    <div
      class="data-column"
      :class="{ 'large' : !filter_visible || condensedView, 'condensed':condensedView }"
    >
      <div v-if="!condensedView" class="filter-bar">
        <div class="bttns">
          <button
            @click="filter_visible = !filter_visible"
            class="btn open-filter"
            :class="{'opened':filter_visible}"
          ></button>
        </div>

        <div class="filters">
          <div
            v-for="(itm, index) in main_filters"
            :key="index"
            class="filter"
            :data-type="itm.type"
          >
            <!-- <div class="label">{{ itm.label }}</div> -->
            <div class="value">
              <v-select
                v-if="itm.type == 'multiple'"
                v-model="itm.current_value"
                :items="getOptions( itm.options, itm )"
                @change="updateFilters()"
                multiple
                chips
                :label="itm.label"
                :placeholder="$t('nvgt.select.all')"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.text }}</span>
                  </v-chip>
                  <span
                    v-if="index === 1"
                    class="caption"
                  >(+{{ itm.current_value.length - 1 }} {{$tc('nvgt.select.selected', itm.current_value.length - 1)}})</span>
                </template>
              </v-select>

              <v-select
                v-if="itm.type == 'select'"
                v-model="itm.current_value"
                :items="getOptions( itm.options, itm )"
                @change="updateFilters()"
                :label="itm.label"
              ></v-select>

              <v-select
                v-if="itm.type == 'selectDate'"
                v-model="itm.current_value"
                :items="getOptions( itm.options, itm )"
                @change="updateFilters()"
              >
                <template v-slot:selection="{ item, index }">
                  <v-list-tile-content>
                    <v-list-tile-title v-html="item.text"></v-list-tile-title>
                    <v-list-tile-sub-title>{{ momentInitEnd(itm.current_value[0], itm.current_value[1]) }}</v-list-tile-sub-title>
                  </v-list-tile-content>
                </template>
                <template v-slot:item="{ item, index }">
                  <v-list-tile-content v-if="!item.action">
                    <v-list-tile-title>{{item.text}}</v-list-tile-title>
                  </v-list-tile-content>
                  <span
                    v-if="item.action"
                    class="btn-select"
                    @click="filterDateRange(item.value)"
                  >{{item.text}}</span>
                </template>
              </v-select>
            </div>
          </div>
        </div>

        <div class="editremove">
          <button class="remove" v-on:click="remove()">{{getString('nvgt.remove')}}</button>
        </div>
      </div>
      <div class="list">
        <div class="marginAdd">
          <div
            class="add"
            v-if="showAdd"
            v-on:click="showAdd=false"
          >{{ getString('nvgt.'+ currentRoute +'.add') }}</div>
        </div>
        <CategoryItem
          :gValue="newItem"
          :gNew="true"
          @callCancel="changeAdd"
          v-if="!showAdd && currentRoute == 'ManageLabelCategory'"
        >Añadir categoría</CategoryItem>
        <CategoryItem
          v-if="currentRoute == 'ManageLabelCategory'"
          v-for="item in values"
          :key="item.id"
          :gValue="item"
          @callUp="upOrder"
          @callDown="downOrder"
          @callSelected="addremoveSelected"
        ></CategoryItem>

        <StateItem
          :gValue="newItem"
          :gNew="true"
          @callCancel="changeAdd"
          v-if="!showAdd && currentRoute == 'ManageLabelState'"
        >Añadir categoría</StateItem>
        <StateItem
          v-if="currentRoute == 'ManageLabelState'"
          v-for="item in values"
          :key="item.id"
          :gValue="item"
          @callUp="upOrder"
          @callDown="downOrder"
          @callSelected="addremoveSelected"
        ></StateItem>
      </div>
    </div>
  </div>
</template>

<script>
import CategoryItem from "@/components/domain/manage/labels/itemList";
import StateItem from "@/components/domain/manage/labels/itemList";

export default {
  name: "List",
  type: "",
  components: { CategoryItem, StateItem },
  props: { schema: Array, data: Object, cType: Number, page: String },
  data() {
    return {
      condensedView: false,
      showAdd: true,
      search: "",
      selected: [],
      newItem: {},
      printOpts: {
        showing: false,
        attach: true,
        feed: true
      },
      previewOpts: {
        showing: false,
        note: true,
        desc: true
      },
      hidden_columns: this.$store.getters["navigation/getCurrentPage"]
        .hidden_columns
        ? this.$store.getters["navigation/getCurrentPage"].hidden_columns
        : [],
      filter_visible: true,
      filters: this.$store.getters["navigation/getCurrentPage"].filters
        ? JSON.parse(
            JSON.stringify(
              this.$store.getters["navigation/getCurrentPage"].filters
            )
          )
        : false,
      selects: this.$store.getters["navigation/getCurrentPage"].selects
        ? JSON.parse(
            JSON.stringify(
              this.$store.getters["navigation/getCurrentPage"].selects
            )
          )
        : false,
      actions: this.$store.getters["navigation/getCurrentPage"].actions
        ? JSON.parse(
            JSON.stringify(
              this.$store.getters["navigation/getCurrentPage"].actions
            )
          )
        : false,
      navInfo: this.$store.getters["navigation/getCurrentPage"]
        ? JSON.parse(
            JSON.stringify(this.$store.getters["navigation/getCurrentPage"])
          )
        : false
    };
  },
  computed: {
    values() {
      return this.data;
    },

    main_filters() {
      return this.selects;
    },
    current_filters() {
      return this.filters;
    },

    allFilters() {
      return [...this.current_filters, ...this.main_filters];
    },
    currentRoute() {
      return this.$route.name;
    }
  },
  methods: {
    addValues() {
      switch (this.currentRoute) {
        case "ManageLabelCategory":
          this.newItem = {
            id: "new",
            account_id: 1,
            zone_id: null,
            name: "",
            icon: "",
            status: 1,
            color: "",
            type: 2,
            order: 1
          };
          break;
        case "ManageLabelState":
          this.newItem = {
            id: "new",
            account_id: 1,
            name: "",
            status: 1,
            priority: 1
          };
          break;
        default:
          break;
      }
      return this.newItem;
    },
    getString(str) {
      return this.$t(str);
    },
    getStringPlural(str, pos) {
      return this.$tc(str, pos);
    },
    getStringVariable(str, pos) {
      return this.$t(str, { s: pos });
    },
    getStringPluralVariable(str, pos, text) {
      return this.$tc(str, pos, { s: text });
    },
    updateFilters() {
      this.$emit("applyFilters", this.allFilters);
    },
    sortedValues(item1, item2) {
      let self = this;
      let store = self.navInfo.storeName;
      let type = self.navInfo.storeType;

      self.$store
        .dispatch(store + "/orderItems", { item: item1, type: type })
        .then(function() {
          self.$store
            .dispatch(store + "/orderItems", { item: item2, type: type })
            .then(function() {
              let call = self.navInfo.loadList
                ? store + "/" + self.navInfo.loadList
                : store + "/loadList";

              self.$store
                .dispatch(call, { type: type })
                .then(function(response) {});
            });
        });

      function compare(a, b) {
        switch (this.currentRoute) {
          case "ManageLabelCategory":
            if (a.order < b.order) return -1;
            if (a.order > b.order) return 1;
            break;
          case "ManageLabelState":
            if (a.priority < b.priority) return -1;
            if (a.priority > b.priority) return 1;
            break;

          default:
            break;
        }

        return 0;
      }

      return Object.values(this.values).sort(compare);
    },
    changeAdd(value) {
      this.showAdd = value;
    },
    upOrder(item1) {
      switch (this.currentRoute) {
        case "ManageLabelCategory":
          var order = item1.order;
          if (order == 1) return;

          var item2 = Object.values(this.values).find(obj => {
            return obj.order == parseInt(order) - 1;
          });
          if (item2 == undefined) return;
          item1.order--;
          item2.order++;
          break;
        case "ManageLabelState":
          var priority = item1.priority;
          if (priority == 1) return;

          var item2 = Object.values(this.values).find(obj => {
            return obj.priority == parseInt(priority) - 1;
          });
          if (item2 == undefined) return;
          item1.priority--;
          item2.priority++;
          break;

        default:
          break;
      }

      this.sortedValues(item1, item2);
    },
    downOrder(item1) {
      switch (this.currentRoute) {
        case "ManageLabelCategory":
          var order = item1.order;
          if (order == Object.keys(this.values).length) return;

          var item2 = Object.values(this.values).find(obj => {
            return obj.order == parseInt(order) + 1;
          });
          if (item2 == undefined) return;
          item1.order++;
          item2.order--;
          break;
        case "ManageLabelState":
          var priority = item1.priority;
          if (priority == Object.keys(this.values).length) return;

          var item2 = Object.values(this.values).find(obj => {
            return obj.priority == parseInt(priority) + 1;
          });
          if (item2 == undefined) return;
          item1.priority++;
          item2.priority--;
          break;
        default:
          break;
      }

      this.sortedValues(item1, item2);
    },
    addremoveSelected(item, status) {
      if (status == 1) {
        this.$set(this.selected, item.id, item);
      } else {
        this.$delete(this.selected, item.id);
      }
    },
    remove() {
      let self = this;
      let store = self.navInfo.storeName;
      let type = self.navInfo.storeType;

      switch (this.currentRoute) {
        case "ManageLabelCategory":
          Object.values(this.selected).forEach(element => {
            self.$store
              .dispatch(store + "/removeCategory", element)
              .then(function(response) {
                self.$router.go();
              });
          });
          break;
        case "ManageLabelState":
          Object.values(this.selected).forEach(element => {
            self.$store
              .dispatch(store + "/removeState", element)
              .then(function(response) {
                self.$router.go();
              });
          });
          break;
        default:
          break;
      }

    },

    getOptions(options, filter) {
      if (typeof options === "string") {
        var aOptions = [];
        if (typeof filter.optionsValues !== "undefined") {
          var values = this.$store.getters[options](filter.optionsValues);
        } else {
          var values = this.$store.getters[options];
        }
        for (var i in values) {
          var value = values[i];
          aOptions.push({ value: value.id, text: value.name });
        }
        return aOptions;
      } else {
        return options;
      }
    }
  },
  created() {
    this.addValues();
  },
  watch: {
    $route(to, from) {
      this.condensedView = false;
      this.selected = [];
      this.search = "";
      this.printOpts = {
        showing: false,
        attach: true,
        feed: true
      };
      this.previewOpts = {
        showing: false,
        note: true,
        desc: true
      };
      this.hidden_columns = this.$store.getters["navigation/getCurrentPage"]
        .hidden_columns
        ? this.$store.getters["navigation/getCurrentPage"].hidden_columns
        : [];
      this.filters = JSON.parse(
        JSON.stringify(this.$store.getters["navigation/getCurrentPage"].filters)
      );
      this.selects = JSON.parse(
        JSON.stringify(this.$store.getters["navigation/getCurrentPage"].selects)
      );
      this.actions = JSON.parse(
        JSON.stringify(this.$store.getters["navigation/getCurrentPage"].actions)
      );
      this.navInfo = JSON.parse(
        JSON.stringify(this.$store.getters["navigation/getCurrentPage"])
      );

      this.addValues();
    }
  },
  mounted() {}
};
</script>
<style lang="scss">
#content.checklist-view .checklist-list {
  @include display-flex();
  @include justify-content(flex-start);
  @include align-items(center);
  @include flex-direction(column);
  .list {
    margin: 0 auto;
    width: 100%;
    .marginAdd {
      margin: 20px 0px 20px 0px;
      .add {
        text-align: center;
        border: 1px #777 solid;
        margin: 0 auto;
        padding: 10px 30px;
        width: 200px;
        &:hover {
          background-color: #8bc0ff;
          cursor: pointer;
        }
      }
    }
    .group:hover {
      .actions,
      .arrows {
        visibility: visible;
      }
      .editMode {
        visibility: visible;
      }
      .editModeArrow {
        visibility: hidden;
      }
    }
    .group:focus {
      .actions,
      .arrows {
        visibility: visible;
      }
    }
  }

  .data-column {
    float: left;
    width: 80%;
    height: 100%;
    margin-left: 20%;

    &.large {
      width: 100%;
      margin-left: 0;
    }
    &.condensed {
      width: 20%;
      min-width: 200px;
      margin-left: 0;
    }

    .datatable-container {
      height: calc(100% - 80px - 60px);
      table-layout: fixed;
      overflow: auto;
    }
  }

  .filter-column {
    float: left;
    width: 20%;
    min-width: 200px;
    padding: 12px 24px;
    border-right: 1px solid #ccc;
    position: fixed;
    left: 0;
    top: 50px;
    height: 100%;
    overflow-y: auto;

    .filter {
      margin-top: 24px;

      .v-menu__content {
        // position: fixed !important;
      }

      &[data-type="6"] {
        label {
          margin-bottom: 50px;
        }
        .value {
          height: 30px;
        }
      }
      &[data-type="multiple"] {
        .value {
          .v-input {
            .v-input__control {
              .v-input__slot {
                .v-select__selections {
                  padding-top: 10px;
                }
              }
            }
          }
        }
      }
      &[data-type="tags"] {
        .tag {
          @include border-radius(3px);
          @include font-size(14px);
          font-family: $text;
          text-align: center;
          display: inline-block;
          // border: 1px solid $color-primary-500;
          background-color: #f2f2f2;
          margin: 4px;
          padding: 6px 9px;
          color: $color-primary-800;
          cursor: pointer;
          transition-duration: 0.2s;

          &:hover {
            background-color: #eee;
          }

          &.all {
            background-color: $color-primary-800;
            color: #fff;

            &:hover {
              background-color: $color-primary-500;
            }
          }
          &.selected {
            background-color: $color-primary-100;

            &:hover {
              background-color: $color-primary-100;
            }
          }
        }
      }
    }

    .input-search {
      @include background(
        $size: 18px,
        $position: left 6px center,
        $image: img("search_aaaaaa.svg")
      );
      width: 100%;
      padding-left: 30px;
    }

    .btn.date-range {
      @include background(
        $color: #eee,
        $size: 18px,
        $image: img("input_date_dark.svg")
      );
      width: 30px;
      height: 30px;
      margin-right: 6px;
    }

    .v-slider__thumb-label span {
      @include font-size(18px);
      color: #fff;
      font-family: $text-bold;

      &:after {
        content: "%";
      }
    }

    .btn.clear-filter {
      @include font-size(16px);
      color: #777;
      display: block;
      margin: 36px auto 0 auto;
      background-color: #eee;
    }
  }

  .filter-bar {
    width: 100%;
    background-color: #fff;
    padding: 15px;
    padding-top: 24px;
    height: 80px;

    .bttns {
      display: inline-block;
      float: left;
      margin-right: 12px;

      .btn {
        @include opacity(0.8);
        @include background($color: #f5f5f5, $size: 18px);
        display: inline-block;
        margin: 0;
        margin-right: 9px;
        padding: 0;
        width: 35px;
        height: 35px;

        &.view-column {
          background-image: img("view_column_dark.svg");
        }
        &.open-filter {
          background-image: img("filter_dark.svg");
          background-size: 16px;

          &.opened {
            @include opacity(1);
            background-image: img("filter_primary.svg");
          }
        }
      }
    }
    .filters {
      @include display-inline-flex();
      @include align-items();
      @include flex-wrap(wrap);
      width: auto;
      max-width: calc(100% - 100px - 150px);

      .filter {
        display: inline-block;
        margin-right: 25px;

        .v-label {
          margin: 0;
          height: unset;
        }
        .value {
          display: inline-block;

          .v-select {
            padding: 0;
            margin: 0;
            height: 40px;
          }
        }
      }

      .filter[data-type="selectDate"] {
        .v-list__tile__content {
          overflow: visible;
        }
        .v-select__selections {
          > input[readonly="readonly"] {
            display: none;
          }
        }

        .v-list__tile__title,
        .v-list__tile__sub-title {
          @include text-ellipsis();
          max-width: 190px;
          height: 20px;
        }
        .v-list__tile__title {
          @include font-size(15px);
        }
        .v-list__tile__sub-title {
          @include font-size(14px);
          text-transform: uppercase;
          font-family: $text;
        }
      }

      // Por que eres así vuetify?
      .v-text-field > .v-input__control > .v-input__slot:before,
      .v-text-field > .v-input__control > .v-input__slot:after {
        bottom: 1px !important;
      }
      .v-select .v-input__slot {
        margin: 0 !important;
      }

      .v-select__selections {
        // @include text-ellipsis();

        width: 190px;
        // margin-top: 3px;
        min-height: 40px !important;
      }
      .v-select__selections .v-chip + input,
      .v-select__selections .caption + input {
        // width: 0px;
        // padding: 0;
        // margin: 0;
      }

      .v-select .v-input__slot .v-menu .v-menu__content {
        // top: 20px !important;
        // left: unset !important;
        // right: 0 !important;
        // max-width: 230px;
      }

      .v-chip .v-chip__content {
        height: 28px;
      }
      .v-chip .v-chip__content span {
        @include text-ellipsis();
        max-width: 100px;
      }
    }

    &.back {
      @include background(
        $image: img("left_arrow_dark.svg"),
        $size: 20px,
        $position: left 12px center
      );
      padding: 0;
      width: 100%;
      height: 50px;
      text-align: right;
      cursor: pointer;

      .text {
        display: inline-block;
        height: 30px;
        line-height: 30px;
        width: calc(100% - 45px);
        color: #666;
        border-left: 1px solid #ddd;
        margin: 10px 0;
        padding-left: 12px;
      }
    }
    .editremove {
      position: relative;
      display: inline-block;
      float: right;

      .remove {
        background-color: #f5f5f5;
        padding: 5px;
        border-radius: 4px;
        margin-left: 10px;
        font-weight: bold;
      }

      .remove:hover {
        color: #8bc0ff;
      }
    }
  }
}
</style>