<template>
    <div id="content" class="checklist-view">
        <List v-if="loadedData" :data="data" @applyFilters="new_filters"></List>
    </div>
</template>

<script>
import List from '@/components/ui/List'

export default {
    name: 'CheckListManagement',
    components: { List },
    props: {
        page: { type: String },
        date: { type: String },
        action: { type: String },
        type: { type: Number, default: -1 }
    },
    data() {
        return {
            loadedData: false
        }
    },
    computed: {
        schema() {
            // console.log(this.$store.getters[ 'navigation/getCurrentPage' ].columns);
            return this.$store.getters['navigation/getCurrentPage'].columns ? this.$store.getters['navigation/getCurrentPage'].columns : []
        },
        filters() {
            return this.$store.getters['navigation/getCurrentPage'].filters ? this.$store.getters['navigation/getCurrentPage'].filters : []
        },
        selects() {
            return this.$store.getters['navigation/getCurrentPage'].selects ? this.$store.getters['navigation/getCurrentPage'].selects : []
        },
        actions() {
            return this.$store.getters['navigation/getCurrentPage'].actions ? this.$store.getters['navigation/getCurrentPage'].actions : {}
        },
        navInfo() {
            return this.$store.getters['navigation/getCurrentPage'] ? this.$store.getters['navigation/getCurrentPage'] : {}
        },
        data() {
            if (typeof this.navInfo.getList != 'undefined') {
                var list = this.$store.getters[this.navInfo.storeName + '/' + this.navInfo.getList]
            } else {
                var list = this.$store.getters[this.navInfo.storeName + '/getList']
            }

            var oList = {}

            for (var i in list) {
                var row = list[i]
                this.$set(oList, row.id, row)
                // oList[ row.id ] = row;
            }

            return oList
            // return list
        }
    },
    created() {
        // console.log( " --- PAGE " + this.page + " --- " );
        this.load()
    },
    watch: {
        $route: 'load'
    },
    methods: {
        new_filters(filters) {
            //   console.log( " --- NEW FILTERS --- " );
            //   console.log( filters );
            var self = this
            var params = {}

            for (var i in filters) {
                params[filters[i].param] = filters[i].current_value
            }
            self.$overlay.show()

            //   console.log("PASA POR FILTERS");
            //   console.log(params);

            let store = self.navInfo.storeName

            let call = self.navInfo.loadList ? store + '/' + self.navInfo.loadList : store + '/loadList'

            self.$store.dispatch(call, params).then(function(response) {
                self.$overlay.hide()
            })

            // self.$store.dispatch( self.navInfo.storeName + '/loadList', params ).then( function(){

            // });
        },

        load() {
            var self = this

            self.$overlay.loading()
            self.$store.commit('setPage', self.page)

            if (typeof self.navInfo.loadList != 'undefined') {
                self.$store.dispatch(self.navInfo.storeName + '/' + self.navInfo.loadList, {}).then(function(response) {
                    self.loadedData = true
                    self.$overlay.hide()
                })
            } else {
                self.$store.dispatch(self.navInfo.storeName + '/loadList', {}).then(function(response) {
                    self.loadedData = true
                    self.$overlay.hide()
                })
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss"></style>
